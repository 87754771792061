@font-face {
  font-family: 'Antique Olive';
  src: url('/assets/fonts/AntiqueOlive-Bold.woff2');
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-Bold.woff2');
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-BoldItalic.woff2');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-Italic.woff2');
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Patron';
  src: url('/assets/fonts/Patron-Regular.woff2');
  font-display: fallback;
}

html {
  height: 100%;
}

body {
  font-family: Patron, system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: var(--step-0);
  background-color: #f9f6f3;
  height: 100%;
  overflow-x: hidden;
}

html,
body {
  scroll-padding-top: 51px;
}

@media (min-width: 1400px) {
  html,
  body {
    scroll-padding-top: 99px;
  }
}

body.mobileDialogOpen {
  overflow-y: hidden;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

a {
  text-decoration: none;
  color: #004a29;
}

#__next {
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Antique Olive', system-ui, -apple-system, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

p {
  line-height: 1.5;
  letter-spacing: -0.02em;
}

summary {
  cursor: pointer;
}

summary:focus {
  outline: none;
}

details summary::-webkit-details-marker,
details summary::marker {
  display: none;
  content: '';
}
